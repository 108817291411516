<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="委外订单号">
					<c-input name="iconsigned_order_number"></c-input>
				</m-search-item>
				<m-search-item label="委外类型">
					<c-radio-group name="title" :options="DIC_ICONSIGNED_TYPE"></c-radio-group>
				</m-search-item>
				<m-search-item label="委托产生商">
					<c-input name="manufacture_name"></c-input>
				</m-search-item>
				<m-search-item label="关联订单">
					<c-input name="order_number"></c-input>
				</m-search-item>
				<m-search-item label="负责员工">
					<c-input name="salesman_name"></c-input>
				</m-search-item>
				<m-search-item label="状态">
					<c-select name="state" :options="stateOptions"></c-select>
				</m-search-item>
				<m-search-item label="创建时间" colspan="2">
					<c-datetime start-name="create_date_start" end-name="create_date_end" range></c-datetime>
				</m-search-item>
				<m-search-item label="计划交期" colspan="2">
					<c-datetime type="date"  start-name="delivery_schedule_date_start" end-name="delivery_schedule_date_end" range></c-datetime>
				</m-search-item>
			</m-search>
			<m-operate>
				<c-button v-if="auth('b_iconsigned_order_edit')" color="sys" @click="editIconsignedOrder()">创建委外单</c-button>
			</m-operate>
			<c-table ref="table" height="grow">
				<c-table-column
					label="委外订单编号"
					width="100"
					name="iconsigned_order_number"
				>
				</c-table-column>
				<c-table-column
					label="委外类型"
					width="100"
					name="title"
				>
				</c-table-column>
				<c-table-column
					label="委托产生商"
					width="120"
					name="manufacture_name"
				>
				</c-table-column>
				<c-table-column
					label="联系人"
					width="80"
					name="manufacture_contacts"
				>
				</c-table-column>
				<c-table-column
					label="联系电话"
					width="90"
					name="manufacture_phone"
				>
				</c-table-column>
				<c-table-column
					label="收货状态"
					width="80"
					name="state"
				>
					<template #default="{data}">
						<span v-if="data.state == -2" style="color:grey">草稿</span>
						<span v-if="data.state == 1" style="color:red">待收货</span>
						<span v-if="data.state == 2" style="color:sandybrown">部分收货</span>
						<span v-if="data.state == 3" style="color:green">已收货</span>
					</template>
				</c-table-column>
				<c-table-column
					label="收货总数"
					width="70"
					name="in_storage_count"
				>
					<template v-slot="{data}">
						<span v-if="data.in_storage_count">{{data.in_storage_count.toFixed(2)}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>

				<c-table-column
					label="不良品数"
					width="70"
					name="defective_product_count"
				>
					<template v-slot="{data}">
						<span v-if="data.defective_product_count">{{data.defective_product_count.toFixed(2)}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>

				<c-table-column
					label="负责员工"
					width="80"
					name="salesman_name"
				>
				</c-table-column>
				<c-table-column
					label="产品项"
					width="70"
					name="product_kinds"
				>
				</c-table-column>
				<c-table-column
					label="计划生产数"
					width="100"
					name="product_count"
				>
					<template v-slot="{data}">
						<span v-if="data.product_count">{{data.product_count.toFixed(2)}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>
				<c-table-column
					label="料数"
					width="70"
					name="material_count"
				>
					<template v-slot="{data}">
						<span v-if="data.material_count">{{data.material_count.toFixed(2)}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>
				<c-table-column
					label="计划交期"
					width="80"
					name="delivery_schedule_date"
				>
				</c-table-column>
				<c-table-column
					label="关联订单"
					width="100"
					name="order_number"
				>
				</c-table-column>

				<c-table-column
					label="创建人"
					width="80"
					name="create_user_realname"
				>
				</c-table-column>
				
				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				>
				</c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="220"
					button-limit="3"
				>
					<template v-slot="{data}">
						<c-table-button @click="navigateTo(`/iconsigned/order/detail/${data.id}`)">详情</c-table-button>
						<c-table-button v-if="data.state == -2 && auth('b_iconsigned_order_edit')" @click="editIconsignedOrder(data)">编辑</c-table-button>
						<c-table-button v-if="data.state == -2 && auth('b_iconsigned_order_edit')" @click="del(data)">删除</c-table-button>
						<c-table-button v-if="(data.state == 1 || data.state == 2) && data.no_out_storage_count>0 && auth('b_iconsigned_order_in_storage')" @click="outStorage(data)">物料出库</c-table-button>
						<c-table-button v-if="(data.state == 1 || data.state == 2) && auth('b_iconsigned_order_in_storage')" @click="inStorage(data)">收货入库</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
		<!--物料出库 弹窗-->
		<iconsigned-order-outstorage ref="outstorageDialog" @resolve="refreshTable()"></iconsigned-order-outstorage>
		<!--产品入库 弹窗-->
		<iconsigned-order-instorage ref="instorageDialog" @resolve="refreshTable()"></iconsigned-order-instorage>
  </page>
</template>
<script>
import iconsignedOrderOutstorage from "@/pages/wms/iconsigned_order_outstorage.vue";
import iconsignedOrderInstorage from "@/pages/wms/iconsigned_order_instorage.vue";
import {asyncMapState} from "@/store";
export default {
	name: 'm_iconsigned_order',
	components: {
		iconsignedOrderOutstorage,iconsignedOrderInstorage
	},
	data() {
		return {
			rowData:null,
			stateOptions: [
				{name: '草稿',value: -2},
				{name: '待收货',value: 1},
				{name: '部分收货',value: 2},
				{name: '已收货',value: 3}
			]
		}
	},
	computed: {
		...asyncMapState(['DIC_ICONSIGNED_TYPE'])
	},
	mounted() {
		this.$refs.search.search();
		this.$bus.$on('iconsignedOrderList.update', page => {
			this.$refs.table.update(page);
		});
	},
	methods: {
		//搜索
		searchHandle(data) {
			this.$refs.table.load({
				url: '/wms/iconsigned/order/list',
				data
			});
		},
		//新增/编辑 委外订单
		editIconsignedOrder(data){
			if (data){
				this.navigateTo('/iconsigned/order/edit/'+data.id);
			}else {
				this.navigateTo('/iconsigned/order/add');
			}
		},
		//删除
		del(val) {
			this.$confirm({
				title: '确认',
				message: '确定要删除委外单【'+val.iconsigned_order_number+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/wms/iconsigned/order/remove',
						data: {id:val.id},
						loading: true,
						success: data => {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//物料出库
		outStorage(data){
			this.$refs.outstorageDialog.open(data);
		},
		//外协完成的成品/半成品入库
		inStorage(data){
			this.$refs.instorageDialog.open(data);
		},
		//刷新列表
		refreshTable(){
			this.$refs.table.update();
		}
  }
}
</script>