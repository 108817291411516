<template>
	<c-dialog ref="dialog" :title="'委外订单【' + orderNumber + '】 - 物料出库'" width="800" @resolve="submit">
		<c-form ref="warehouseForm" unit-width="100%">
			<c-form-item label="出库仓库" required>
				<c-select v-model="warehouse_id" name="warehouse_id" :options="warehouse_list"></c-select>
			</c-form-item>
		</c-form>
		<c-table ref="table" height="500" :data="materialData" :paging="false" border>
			<c-table-column
				type="selector"
				width="20"
			></c-table-column>

<!--			<c-table-column
				label="物料编码"
				name="product_code"
				width="100"
			>
			</c-table-column>-->

			<c-table-column
				label="物料名称"
				name="product_name"
				width="150"
			>
			</c-table-column>

			<c-table-column
				label="单位"
				name="unit"
				width="50"
			>
			</c-table-column>

			<c-table-column
				label="物料库存"
				name="stock_count"
				width="70"
			>
			</c-table-column>

			<c-table-column
				label="计划生产数量"
				name="product_count"
				width="70"
			>
			</c-table-column>

			<c-table-column
				label="已出库"
				name="out_storage_count"
				width="70"
			>
			</c-table-column>

			<c-table-column
				label="出库数量"
				width="80"
				name="new_out_storage_count"
				required
			>
				<template v-slot="{data}">
					<c-input v-model="data.new_out_storage_count" decimal="5" type="number" :range="[0,data.max_out_storage_count]"></c-input>
				</template>
			</c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		computed: {
			...mapState(['systemConfig']),
		},
		
		data() {
			return {
				warehouse_list:[],
				warehouse_id:0,
				iconsigned_order_id:0,
				orderNumber: '',
				materialData: []
			}
		},
		watch: {
			warehouse_id() {
				this.loadMaterialList();
			},
		},
		methods: {
			//查询仓库列表
			initWarehouse(){
				this.request({
					url: '/warehouse/list',
					data: {
						type: 2,       //物料仓
						pageSize: 9999
					},
					success: data => {
						this.warehouse_list = Array.from(data||[], item => {
							return {
								name: item.warehouse_name,
								value: item.id,
								type: item.type
							}
						});
						if (this.warehouse_list && this.warehouse_list.length > 0){
							this.warehouse_id = this.warehouse_list[0].value;
						}
					}
				})
			},
			//加载数据
			loadMaterialList(){
				this.request({
					url: '/wms/iconsigned/order/unoutstorage/material/list',
					data: {
						id:this.iconsigned_order_id,
						warehouse_id:this.warehouse_id
					},
					loading: true,
					success: async data => {
						this.materialData = data||[];
						this.materialData.forEach(item => {
							item.max_out_storage_count = item.product_count-item.out_storage_count;
							if(item.stock_count < 0){
								item.max_out_storage_count = "0";
							}else if (item.max_out_storage_count > item.stock_count){
								item.max_out_storage_count = item.stock_count;
							}
						});
					}
				});
			},
			open(data) {
				if (!this.warehouse_list || this.warehouse_list.length==0){
					this.initWarehouse();
				}
				this.orderNumber = data.iconsigned_order_number;
				this.iconsigned_order_id = data.id;
				this.loadMaterialList();
				this.$refs.dialog.open();
			},
			
			submit(stop) {
				var flag = true;
				var data = this.$refs.table.getRow('selected');
				if (!data.length) {
					stop();
					this.$message({
						message: '请选择需要出库的物料'
					});
					return;
				};
				var list = Array.from(data, item => item.data);
				list.forEach(item => {
					var new_out_storage_count = parseFloat(item.new_out_storage_count||0);
					if(new_out_storage_count<=0){
						stop();
						this.$message({message: '出库数量必须大于0'});
						flag = false;
						return;
					}
					var out_storage_count = parseFloat(item.out_storage_count||0);
					var product_count = parseFloat(item.product_count||0);
					if (product_count < (out_storage_count + new_out_storage_count)){
						stop();
						this.$message({message: '出库数量不能超过计划生产数量'});
						flag = false;
						return;
					}
					if (item.stock_count < new_out_storage_count){
						stop();
						this.$message({message: '出库数量不能超过库存数量'});
						flag = false;
						return;
					}
				});
				//执行订单出库
				if (flag){
					this.request({
						url: '/wms/iconsigned/order/out_storage',
						data: {
							id: this.iconsigned_order_id,
							warehouse_id: this.warehouse_id,
							product_list:list
						},
						loading: true,
						success: data => {
							this.$message({
								type: 'success',
								message: '操作成功'
							});
							this.$emit('resolve');
						}
					});
				}
			}
		}
	}
</script>