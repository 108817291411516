<template>
	<c-dialog ref="dialog" :title="'委外订单【' + orderNumber + '】 - 产品入库'" width="1000" @resolve="submit">
		<c-form ref="warehouseForm" unit-width="100%">
			<c-form-item label="入库仓库" required>
				<c-select v-model="warehouse_id" name="warehouse_id" :options="warehouse_list"></c-select>
			</c-form-item>
		</c-form>
		<c-table ref="table" height="500" :data="productData" :paging="false" border>
			<c-table-column
				type="selector"
				width="20"
			></c-table-column>

			<c-table-column
				label="产品类型"
				name="product_type"
				width="80"
			>
				<template #default="{data}">
					<span v-if="data.product_type==1">成品</span>
					<span v-if="data.product_type==2">物料</span>
				</template>
			</c-table-column>

<!--			<c-table-column
				label="产品编码"
				name="product_code"
				width="100"
			>
			</c-table-column>-->

			<c-table-column
				label="产品名称"
				name="product_name"
				width="150"
			>
			</c-table-column>

			<c-table-column
				label="产品颜色"
				name="product_name"
				width="150"
			>
			</c-table-column>

			<c-table-column
				label="单位"
				name="unit"
				width="50"
			>
			</c-table-column>

			<c-table-column
				label="计划生产数量"
				name="product_count"
				width="70"
			>
			</c-table-column>

			<c-table-column
				label="已入库"
				name="in_storage_count"
				width="70"
			>
			</c-table-column>

			<c-table-column
				label="不良品数"
				name="defective_product_count"
				width="70"
			>
			</c-table-column>

			<c-table-column
				label="此次入库"
				width="100"
				name="new_in_storage_count"
				required
			>
				<template v-slot="{data}">
					<c-input v-model="data.new_in_storage_count" type="number" decimal="5" :range="[0,data.max_in_storage_count]"></c-input>
				</template>
			</c-table-column>
			<c-table-column
				label="此次不良品"
				width="100"
				name="new_defective_product_count"
				required
			>
				<template v-slot="{data}">
					<c-input v-model="data.new_defective_product_count" type="number" decimal="5" :range="[0,data.max_in_storage_count]"></c-input>
				</template>
			</c-table-column>
		</c-table>
	</c-dialog>
</template>

<script>
	import {mapState} from 'vuex'
	export default {
		computed: {
			...mapState(['systemConfig']),
		},
		
		data() {
			return {
				warehouse_list:[],
				warehouse_id:0,
				iconsigned_order_id:0,
				orderNumber: '',
				productData: []
			}
		},
		watch: {
		},
		methods: {
			/**
			 * 查询仓库列表
			 * @param warehouse_type  1=成品仓 2=物料仓
			 */
			initWarehouse(warehouse_type){
				this.request({
					url: '/warehouse/list',
					data: {
						type: warehouse_type,       //物料仓
						pageSize: 9999
					},
					success: data => {
						this.warehouse_list = Array.from(data||[], item => {
							return {
								name: item.warehouse_name,
								value: item.id,
								type: item.type
							}
						});
						if (this.warehouse_list && this.warehouse_list.length > 0){
							this.warehouse_id = this.warehouse_list[0].value;
						}
					}
				})
			},
			//加载数据
			loadProductList(){
				this.request({
					url: '/wms/iconsigned/order/uninstorage/product/list',
					data: {
						id:this.iconsigned_order_id
					},
					loading: true,
					success: async data => {
						this.productData = data||[];
						this.productData.forEach(item => {
							item.max_in_storage_count = item.product_count - item.in_storage_count - item.defective_product_count;
							item.new_in_storage_count = item.product_count - item.in_storage_count - item.defective_product_count;
							item.new_defective_product_count = 0;
						});
					}
				});
			},
			open(data) {
				this.initWarehouse(data.product_type||1);
				this.orderNumber = data.iconsigned_order_number;
				this.iconsigned_order_id = data.id;
				this.loadProductList();
				this.$refs.dialog.open();
			},
			
			submit(stop) {
				var flag = true;
				var data = this.$refs.table.getRow('selected');
				if (!data.length) {
					stop();
					this.$message({
						message: '请选择需要入库的物料'
					});
					return;
				};
				var list = Array.from(data, item => item.data);
				list.forEach(item => {
					var new_in_storage_count = parseFloat(item.new_in_storage_count||0);
					var new_defective_product_count = parseFloat(item.new_defective_product_count||0);
					if(new_in_storage_count<=0){
						stop();
						this.$message({message: '入库数量必须大于0'});
						flag = false;
						return;
					}
					if(new_defective_product_count < 0){
						stop();
						this.$message({message: '不良品数量不能小于0'});
						flag = false;
						return;
					}
					var in_storage_count = parseFloat(item.in_storage_count||0);
					var product_count = parseFloat(item.product_count||0);
					var defective_product_count = parseFloat(item.defective_product_count||0);
					if (product_count < (in_storage_count + defective_product_count + new_in_storage_count + new_defective_product_count)){
						stop();
						this.$message({message: '入库数和不良品数之和不能超过计划生产数量'});
						flag = false;
						return;
					}
				});
				//执行订单入库
				if (flag){
					this.request({
						url: '/wms/iconsigned/order/in_storage',
						data: {
							id: this.iconsigned_order_id,
							warehouse_id: this.warehouse_id,
							product_list:list
						},
						loading: true,
						success: data => {
							this.$message({
								type: 'success',
								message: '操作成功'
							});
							this.$emit('resolve');
						}
					});
				}
			}
		}
	}
</script>